import type { MetaFunction } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { Link, Grid } from "@radix-ui/themes";

export const meta: MetaFunction = () => {
  return [
    { title: "AIGC Demo" },
    { name: "description", content: "Welcome to AIGC demo!" },
  ];
};

export default function Index() {
  return (
    <div style={{ fontFamily: "system-ui, sans-serif", lineHeight: "1.8" }}>
      <h1>Welcome to my AIGC Demo</h1>
      Link to
      <Grid columns="3" gap="3" rows="repeat(2, 64px)" width="auto">
        <Link href="/LLMs">LLMs</Link>
        <Link href="/audio">Audio</Link>
        <Link href="/image">Images</Link>
      </Grid>
      <Outlet />
      {/* 尾巴 */}
    </div>
  );
}
